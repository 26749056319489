.App {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Editor-wrapper {
  width: 50%;
  height: 100vh;
  border-left: solid 1px lightgray;
  border-right: solid 1px lightgray;
}

.Result {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Credits {
  margin: 0;
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 15px;
}

.Credits a {
  text-decoration: none;
  color: inherit;
  font-weight: 700;
}
