.Block-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border-radius: 5px;
}

.Block-wrapper[data-focus=true], .Block-wrapper:hover {
  background-color: #f7f7f7;
}

.Block-wrapper:hover .Block-menu-trigger {
  opacity: 1;
}

.Block-wrapper .Block-menu-trigger {
  opacity: 0;
  position: absolute;
  left: -25px;
  top: 0;
  margin: 5px 0;
  font-size: 20px;
  cursor: pointer;
}

.Block-wrapper .Block-menu-trigger:hover {
  opacity: 1;
}

.Block-wrapper .Block-nest-trigger {
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  cursor: pointer;
}

.Block-wrapper .Block-menu {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 112px;
  top: 30px;
  left: -125px;
  padding: 5px;
  border: solid 1px #f7f7f7;
  border-radius: 5px;
}

.Block-wrapper .Block-menu .Block-menu-item {
  height: 20px;
  width: 20px;
  padding: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: solid 1px #f7f7f7;
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
  font-size: 13px;
}

.Block-wrapper .Block-menu .Block-menu-item.green {
  background-color: #e9edc9;
}

.Block-wrapper .Block-menu .Block-menu-item.yellow {
  background-color: #fcf6bd;
}

.Block-wrapper .Block-menu .Block-menu-item.red {
  background-color: #fec5bb;
}

.Block-wrapper .Block-dot {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #333;
  margin: 12px 1em;
  cursor: pointer;
}

.Block-wrapper .Block-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 5px 10px;
  min-height: 20px;
  outline: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.Block-wrapper .Block-content.green {
  background-color: #e9edc9;
}

.Block-wrapper .Block-content.yellow {
  background-color: #fcf6bd;
}

.Block-wrapper .Block-content.red {
  background-color: #fec5bb;
}

.Block-wrapper .Block-content.base {
  font-size: 14px;
}

.Block-wrapper .Block-content.heading1 {
  font-size: 24px;
  font-weight: 700;
}

.Block-wrapper .Block-content.heading2 {
  font-size: 21px;
  font-weight: 700;
}

.Block-wrapper .Block-content.heading3 {
  font-size: 18px;
  font-weight: 700;
}

.Editor-indent {
  padding-left: 2em;
}
